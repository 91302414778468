import cn from "classnames";
import React from "react";

import styles from "./MatchingIcon.module.scss";

interface IProps {
  variant: "black" | "blue" | "green" | "group";
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  title?: string;
  isWithAttentionSign?: boolean;
}

const MatchingIcon = ({ onClick, variant, className, disabled, title, isWithAttentionSign }: IProps) => {
  return (
    <button className={cn(styles.container, className)} type="button" disabled={disabled} title={title}>
      <div
        onClick={!disabled ? onClick : undefined}
        className={cn(styles.icon, {
          [styles.black]: variant === "black",
          [styles.blue]: variant === "blue",
          [styles.green]: variant === "green",
          [styles.group]: variant === "group",
        })}
      />
      {isWithAttentionSign && <span className={styles.attention}>!</span>}
    </button>
  );
};

export default React.memo(MatchingIcon);
