import cn from "classnames";
import moment from "moment";
import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";

import objectImg from "./object.jpg";
import ObjectExtendedRowItem from "./ui/ObjectExtendedRowItem/ObjectExtendedRowItem";
import ObjectExtendedSubRow from "./ui/ObjectExtendedSubRow/ObjectExtendedSubRow";
import ObjectsRowChartsPopup from "./ui/ObjectsRowChartsPopup/ObjectsRowChartsPopup";
import ObjectRowMasteringCell from "./widgets/ObjectRowMasteringCell/ObjectRowMasteringCell";
import { useObjectMastering } from "./widgets/ObjectRowMasteringCell/useObjectMastering";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { IObjectInList } from "features/objectsList/model/types";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import SettingGear from "images/SettingGear";

import styles from "./ObjectsRowExtended.module.scss";

interface IProps {
  object: IObjectInList;
  viewHandlerPermission: boolean;
  viewEditBuildingPermission: boolean;
  viewBuildingPermission: boolean;
  projectId: string;
}

const displayValue = (val: any, isHighlighting?: boolean) => ({
  title: transformDigitToFinancial(val ?? 0, { withCurrencySign: true, withFloat: true }),
  children: (
    <span className={cn(styles.nums, { [styles.red]: isHighlighting && +val < 0 })}>
      {transformDigitToFinancial(Math.round(+(val ?? 0)), { withCurrencySign: true, withFloat: false })}
    </span>
  ),
});

const ObjectsRowExtended: FC<IProps> = ({
  object,
  viewBuildingPermission,
  viewEditBuildingPermission,
  viewHandlerPermission,
  projectId,
}) => {
  const history = useHistory();
  const today = moment().format("D MMM YYYY");

  //const endOfMonth = moment().endOf("month").format("D MMM YYYY");

  const data = useMemo(() => {
    return object?.calculation ?? ({} as IObjectInList["calculation"]);
  }, [object]);

  const onSettingsClick = () => {
    history.push(`/constructing/projects/edit-object/${projectId}/${object.id}/passport`);
  };

  const onEstimateClick = () => {
    history.push(`/constructing/handler/${projectId}/${object.id}`);
  };

  const onRowClick = () => {
    if (viewBuildingPermission) history.push(`/constructing/projects/${projectId}/object/${object.id}`);
  };

  const factCharts = useMemo(() => {
    return [
      { name: "Выполнено", percents: data.completed_percent ?? 0, value: data.completed ?? 0, color: "#DDC3F4" },
      { name: "Принято", percents: data.accepted_percent ?? 0, value: data.accepted ?? 0, color: "#8157E6" },
      { name: "К сдаче", percents: data.to_do_percent ?? 0, value: data.to_do ?? 0, color: "#76B1E9" },
    ];
  }, [data]);

  const planCharts = useMemo(() => {
    return [
      {
        name: "Смета",
        percents: Math.round(((Number(data.today_estimate) || 0) * 100) / (Number(data.estimate_plan) || 1)) ?? 0,
        value: data.today_estimate ?? 0,
        color: "#95A5FF",
      },
      {
        name: "Бюджет",
        percents: Math.round(((Number(data.today_budget) || 0) * 100) / (Number(data.budget) || 1)) ?? 0,
        value: data.today_budget ?? 0,
        color: "#27C400",
      },
      /*       { name: "Факт", percents: 0, value: data.accepted, color: "#38C0C9" }, */
    ];
  }, [data]);

  return (
    <div className={styles.root} onClick={onRowClick}>
      <header className={styles.head}>
        <TableReusableCell className={styles.name} isNoBreak isFont13={false}>
          {object.name}
        </TableReusableCell>
        <TableReusableCell className={styles.entity} isNoBreak isFont13={false}>
          {object.entity_name}
        </TableReusableCell>
        <TableReusableCell className={styles.entity} isNoBreak isFont13={false}>
          {object.customer}
        </TableReusableCell>
        <div className={styles.buttonGroup} onClick={stopEventPropagation}>
          {viewHandlerPermission && (
            <div className={styles.actionButton} onClick={onEstimateClick}>
              Смета
            </div>
          )}
          {viewEditBuildingPermission && (
            <div
              className={styles.actionButton}
              onClick={onSettingsClick}
              data-testid={`object_row_${object.id}_settings`}
            >
              <SettingGear color="#fff" />
            </div>
          )}
        </div>
      </header>
      <main className={styles.body}>
        <section className={styles.mainCell}>
          <div className={styles.imgWrapper}>
            <img src={objectImg} alt="Object" />
          </div>
          <div className={styles.mainInfo}>
            <div className={styles.days}>
              <span>{data.days_until_end ?? 0}</span>&nbsp;дней
            </div>
            <div className={styles.ending}>До завершения</div>
          </div>
        </section>
        <ObjectExtendedSubRow className={styles.topRow} indicator={"Бюджет"}>
          <div className={styles.budget}>
            <ObjectExtendedRowItem name="Смета:" title={displayValue(data.budget).title}>
              {displayValue(data.budget).children}
            </ObjectExtendedRowItem>
            <ObjectExtendedRowItem name="План:" title={displayValue(data.estimate_plan).title}>
              {displayValue(data.estimate_plan).children}
            </ObjectExtendedRowItem>
            <ObjectExtendedRowItem name="Факт:" title={displayValue(data.doned).title}>
              {displayValue(data.doned).children}
            </ObjectExtendedRowItem>
          </div>
          <ObjectExtendedSubRow
            indicatorClassName={styles.deviationIndicator}
            className={styles.deviation}
            indicator={"Отклонения бюджета"}
          >
            {/*  */}
            <ObjectExtendedRowItem
              name="Факт - План:"
              percents={Math.round(data.fact_plan_percent ?? 0)}
              title={displayValue(data.fact_plan_diff).title}
              highlightPercentsOverflowing
            >
              {displayValue(data.fact_plan_diff, true).children}
            </ObjectExtendedRowItem>
            {/*  */}
            <ObjectExtendedRowItem
              name="Факт - Смета:"
              percents={Math.round(data.fact_budget_percent ?? 0)}
              title={displayValue(data.fact_budget_diff).title}
              highlightPercentsOverflowing
            >
              {displayValue(data.fact_budget_diff, true).children}
            </ObjectExtendedRowItem>
          </ObjectExtendedSubRow>
        </ObjectExtendedSubRow>
        {/*  */}
        <ObjectExtendedSubRow className={styles.secondRow} indicator={`Освоение ${today}`}>
          <ObjectExtendedRowItem
            name="Выполнение"
            title={Math.round(data.completed_percent ?? 0) + "%"}
            percents={Math.round(data.completed_percent ?? 0)}
          ></ObjectExtendedRowItem>
          <ObjectExtendedRowItem name="План:" title={displayValue(data.plan_development).title}>
            {displayValue(data.plan_development).children}
            <ObjectsRowChartsPopup items={planCharts} />
          </ObjectExtendedRowItem>
          <ObjectExtendedRowItem name="Факт:" title={displayValue(data.plan_subsections).title}>
            {displayValue(data.plan_subsections).children}
            <ObjectsRowChartsPopup items={factCharts} />
          </ObjectExtendedRowItem>
        </ObjectExtendedSubRow>
        <ObjectRowMasteringCell objectId={object.id} />
      </main>
    </div>
  );
};

export default ObjectsRowExtended;
