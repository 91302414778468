import { message } from "antd";
import { Dispatch } from "redux";

import {
  ADD_MORE_SERVICE_ACT_FILES,
  CUT_OFF_SERVICE_ACT_FILE,
  SET_IS_SERVICE_ACT_NOT_FOUND,
  SET_SERVICE_ACT,
  SET_SERVICE_ACTS_LIST_LOADING,
  SET_SERVICE_ACT_FILES,
  SET_SERVICE_ACT_LOADING,
  setIsAcceptingServiceAct,
  setMoreServiceActsList,
  setServiceActsList,
} from "./actions";
import { serviceActsApi } from "./api";
import { RootState } from "app/store/rootReducer";

import { IActsListFilter } from "./types";
import { IFile } from "types/interfaces/Files";
import { IServiceAct } from "types/interfaces/ServiceAct";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getServiceActsList =
  (params: IActsListFilter, isLoadMore = false) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().serviceActs.serviceActsList?.results?.length) {
      dispatch({
        type: SET_SERVICE_ACTS_LIST_LOADING,
        payload: true,
      });
    }

    serviceActsApi
      .getActsList(params)
      .then(({ data }) => {
        if (isLoadMore) dispatch(setMoreServiceActsList(data));
        else dispatch(setServiceActsList(data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch({
          type: SET_SERVICE_ACTS_LIST_LOADING,
          payload: false,
        });
      });
  };

export const getServiceAct = (id: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SERVICE_ACT_LOADING,
    payload: true,
  });

  dispatch({
    type: SET_IS_SERVICE_ACT_NOT_FOUND,
    payload: false,
  });

  serviceActsApi
    .getAct(id)
    .then(({ data }) => {
      dispatch({
        type: SET_SERVICE_ACT,
        payload: data,
      });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch({
          type: SET_IS_SERVICE_ACT_NOT_FOUND,
          payload: true,
        });
      } else {
        errorCatcher(error);
      }
    })
    .finally(() => {
      dispatch({
        type: SET_SERVICE_ACT_LOADING,
        payload: false,
      });
    });
};

export const changeServiceActNumber = (actId: string, number: string) => (dispatch: Dispatch) => {
  if (!number) return;

  serviceActsApi
    .changeServiceActNumber(number, actId)
    .then(({ data }) => {
      dispatch({
        type: SET_SERVICE_ACT,
        payload: data,
      });
      message.success("Номер акта успешно изменен");
    })
    .catch(errorCatcher);
};

export const changeAct = (actId: string, data: Partial<IServiceAct>, successMsg?: string) => (dispatch: Dispatch) => {
  serviceActsApi
    .changeAct(actId, data)
    .then(({ data }) => {
      successMsg && message.success(successMsg);

      dispatch({
        type: SET_SERVICE_ACT,
        payload: data,
      });
    })
    .catch(errorCatcher);
};

export const acceptServiceActRequest = (actId: string, data: any, successCb?: () => void) => (dispatch: Dispatch) => {
  dispatch(setIsAcceptingServiceAct(true));
  serviceActsApi
    .acceptAct(actId, data)
    .then(({ data }) => {
      message.success("Акт успешно принят");
      successCb?.();
      dispatch({
        type: SET_SERVICE_ACT,
        payload: data,
      });
    })
    .catch(errorCatcher)
    .finally(() => dispatch(setIsAcceptingServiceAct(false)));
};

export const getServiceActFiles = (actId: string) => (dispatch: Dispatch) => {
  serviceActsApi.getActFiles(actId).then(({ data }) => {
    dispatch({
      type: SET_SERVICE_ACT_FILES,
      payload: data.results,
    });
  });
};

export const postServiceActFiles = (actId: string, files: IFile[]) => (dispatch: Dispatch) => {
  serviceActsApi
    .postActFiles(actId, files)
    .then(({ data }) => {
      dispatch({
        type: ADD_MORE_SERVICE_ACT_FILES,
        payload: data,
      });
    })
    .catch(errorCatcher);
};

export const deleteServiceActFile = (actId: string, fileId: number) => (dispatch: Dispatch) => {
  serviceActsApi
    .deleteActFile(actId, fileId)
    .then(() => {
      dispatch({
        type: CUT_OFF_SERVICE_ACT_FILE,
        payload: fileId,
      });
    })
    .catch(errorCatcher);
};
