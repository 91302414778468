import axios from "axios";

import {
  IGetMimsListParams,
  IMimStockResponsible,
  IMimsStockIdResponse,
  IMimsStockItem,
  IMimsStockSendToDownTimeRequest,
  IMimsStockSendToWorkRequest,
} from "./types";
import { IListResponseData } from "types/ListResponse";

export const mimsStockApi = {
  getMimsStock: (building_id: string | number) => {
    return axios.get<IListResponseData<IMimsStockIdResponse>>(`/mims/stocks/`, { params: { building_id } });
  },
  getList: (stockId: number, params?: Partial<IGetMimsListParams>) => {
    return axios.get<IListResponseData<IMimsStockItem>>(`/mims/${stockId}/`, { params: { ...params, limit: 500 } });
  },
  getProvider: (id: number) => {
    return axios.get(`/partnership/virtual-providers/${id}/`);
  },
  sendToWork: (stockId: number, mimId: number, data: IMimsStockSendToWorkRequest) => {
    return axios.post(`/mims/${stockId}/${mimId}/to_work/`, data);
  },
  linkWithEstimate: (stockId: number, mimId: number, expId: number) => {
    return axios.post(`/mims/${stockId}/${mimId}/link_with_estimate/`, { estimate_expenditure_id: expId });
  },
  sendToDownTime: (stockId: number, mimId: number, data: IMimsStockSendToDownTimeRequest) => {
    return axios.post(`/mims/${stockId}/${mimId}/to_downtime/`, data);
  },
  getResponsibles: (stockId: number) => {
    return axios.get<IMimStockResponsible[]>(`/mims/${stockId}/responsiblies/`);
  },
  getFiles: (stockId: number, mimId: number) => {
    return axios.get(`/mims/${stockId}/${mimId}/files/`, { params: { limit: 500 } });
  },
  postFiles: (stockId: number, mimId: number, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post(`/mims/${stockId}/${mimId}/files/`, formData);
  },
  deleteFiles: (stockId: number, mimId: number, fileId: number) => {
    return axios.delete(`/mims/${stockId}/${mimId}/files/${fileId}`);
  },
};
