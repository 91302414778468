// @ts-nocheck
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  JOURNAL_INDICATORS,
  PROJECTS_INDICATORS,
  PROJECT_INDICATORS,
} from "../../../redux/modules/common/uiIndicators/types";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import { useUIIndicator } from "../../../hooks/uiIndicators/useUIIndicator";
import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";
import StocksProcurementsButton from "pages/Stocks/StocksProcurementsButton";

import MenuItem from "./MenuItem";
import { useCheckIsConstructingControl } from "features/constructingControl";
import { useTranslation } from "react-i18next";

import { MODULES_ENUM } from "../../../types/enums/ModulesEnum";
import {
  VIEW_ANALYTICS,
  VIEW_BUILDINGS_SECTION,
  VIEW_CONSTRUCTING_CHART,
  VIEW_COUNTERPARTIES_SECTION as VIEW_CONSTRUCTING_COUNTERPARTIES_SECTION,
  VIEW_DOCUMENTS_SECTION as VIEW_CONSTRUCTING_DOCUMENTS_SECTION,
  VIEW_FINANCE_SECTION,
} from "constants/permissions/constructingPermissions";
import { VIEW_SETTINGS_SECTION } from "constants/permissions/generalPermissions";
import {
  VIEW_MANUFACTURING_BETON,
  VIEW_MANUFACTURING_CHART,
  VIEW_MANUFACTURING_JOURNAL,
  VIEW_MANUFACTURING_MATERIALS_SECTION,
  VIEW_MANUFACTURING_PLAN_FACT_SECTION,
  VIEW_MANUFACTURING_PROJECT,
  VIEW_MANUFACTURING_REQUISITION_SECTION,
  VIEW_MANUFACTURING_STOCKS_SECTION,
  VIEW_MANUFACTURING_WORKERS,
} from "constants/permissions/manufacturingPermissions";
import {
  ORDERS_SECTION_VIEW,
  VIEW_PURCHASES_CHART,
  VIEW_COUNTERPARTIES_SECTION as VIEW_PURCHASES_COUNTERPARTIES_SECTION,
  VIEW_DOCUMENTS_SECTION as VIEW_PURCHASES_DOCUMENTS_SECTION,
  VIEW_REQUISITIONS_LIST,
} from "constants/permissions/purchasesPermissions";

import usePermission from "hooks/usePermission";

import analyticsIcon from "images/icons/navigation/analyticsIcon.svg";
import applications from "images/icons/navigation/applications.svg";
import beton from "images/icons/navigation/beton.svg";
import contractors from "images/icons/navigation/contractors.svg";
import estimateIcon from "images/icons/navigation/estimateIcon.svg";
import finance from "images/icons/navigation/finance.svg";
import graphIcon from "images/icons/navigation/graphIcon.svg";
import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";
import materials from "images/icons/navigation/materials.svg";
import mimsIcon from "images/icons/navigation/mims.svg";
import planFact from "images/icons/navigation/plan-fact.svg";
import scheduleIcon from "images/icons/navigation/scheduleIcon.svg";
import settingGear from "images/icons/navigation/settingGear.svg";
import taskIcon from "images/icons/navigation/tasksIcon.svg";
import workersIcon from "images/icons/navigation/timesheetIcon.svg";
import twoListsIcon from "images/icons/navigation/twoListsIcon.svg";
import warehouses from "images/icons/navigation/warehouses.svg";
import tasksAll from "images/menu-icons/tasksAll.svg";
import tasksAssigned from "images/menu-icons/tasksAssigned.svg";
import tasksLists from "images/menu-icons/tasksLists.svg";
import tasksMy from "images/menu-icons/tasksMy.svg";
import tasksViewing from "images/menu-icons/tasksViewing.svg";

import styles from "./index.module.scss";

const NavigationBar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const locationArray = location.pathname.split("/").filter((item) => item) || [];
  const { objectId } = useParams();
  const objects = useSelector(objectsBreadcrumbsSelector);

  const { isIndicatorDisplayed: hasJournalIndicator } = useUIIndicator({
    data: {},
    module: MODULES_ENUM.OBJECTS,
    type: JOURNAL_INDICATORS,
    buildingId: objectId,
  });

  const { isIndicatorDisplayed: hasProjectsIndicator } = useUIIndicator({
    data: {},
    module: MODULES_ENUM.CONSTRUCTING,
    type: PROJECTS_INDICATORS,
  });

  const { isIndicatorDisplayed: hasProjectIndicator } = useUIIndicator({
    data: {},
    module: MODULES_ENUM.OBJECTS,
    type: PROJECT_INDICATORS,
  });

  const objectIdAsNumber = objectId || 0;

  const haveViewRequisitionsListPermission = usePermission(VIEW_REQUISITIONS_LIST);
  const haveViewOrdersListPermission = usePermission(ORDERS_SECTION_VIEW);

  const haveViewPurchasesDocumentsPermission = usePermission(VIEW_PURCHASES_DOCUMENTS_SECTION);
  const haveViewPurchasesCounterpartiesPermission = usePermission(VIEW_PURCHASES_COUNTERPARTIES_SECTION);
  const haveViewBuildingsSectionPermission = usePermission(VIEW_BUILDINGS_SECTION);
  const haveViewFinanceSectionPermission = usePermission(VIEW_FINANCE_SECTION);
  const haveViewConstructingCounterpartiesPermission = usePermission(VIEW_CONSTRUCTING_COUNTERPARTIES_SECTION);
  const haveViewConstructingDocumentsPermission = usePermission(VIEW_CONSTRUCTING_DOCUMENTS_SECTION);
  const haveViewConstructingAnalyticsPermission = usePermission(VIEW_ANALYTICS);

  const haveViewWorkersPermission = usePermission(VIEW_MANUFACTURING_WORKERS);
  const haveViewJournalPermisiion = usePermission(VIEW_MANUFACTURING_JOURNAL);
  const haveViewProjectPermission = usePermission(VIEW_MANUFACTURING_PROJECT);
  const haveViewManufacturingChartPermission = usePermission(VIEW_MANUFACTURING_CHART);
  const haveViewStocksPermission = usePermission(VIEW_MANUFACTURING_STOCKS_SECTION);
  const haveViewBetonPermission = usePermission(VIEW_MANUFACTURING_BETON);
  const haveManufacturingRequisitionsListPermission = usePermission(VIEW_MANUFACTURING_REQUISITION_SECTION);
  const haveViewMaterialsPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_SECTION);
  const haveViewPlanFactPermission = usePermission(VIEW_MANUFACTURING_PLAN_FACT_SECTION);
  const haveViewSettingsSectionPermission = usePermission(VIEW_SETTINGS_SECTION);

  const haveViewConstructingChartPermission = usePermission(VIEW_CONSTRUCTING_CHART);
  const haveViewPurchasesChartPermission = usePermission(VIEW_PURCHASES_CHART);
  const isConstructingControl = useCheckIsConstructingControl();

  //TODO
  const productionsMenu = () => {
    const cachedObjectId = localStorage.getItem("lastObjectId") || objectId;

    if (!objects?.results?.length) return null;

    return (
      <>
        {locationArray.length > 1 && (
          <>
            <MenuItem
              isExists={haveViewProjectPermission}
              title={t("Проект")}
              link={`/objects/${cachedObjectId}/estimate`}
              icon={estimateIcon}
              isDirectlyActive={locationArray[2] === "estimate"}
              isWithIndicator={hasProjectIndicator}
            />
            <MenuItem
              isExists={haveViewManufacturingChartPermission}
              title={t("График")}
              link={`/objects${cachedObjectId ? "/" + cachedObjectId : ""}/manufacturing/`}
              icon={scheduleIcon}
              isDirectlyActive={locationArray.includes("manufacturing")}
            />
            <MenuItem
              isExists={haveViewJournalPermisiion}
              title={t("Журнал учета")}
              link={`/objects/${cachedObjectId}/journal`}
              icon={jurnalIcon}
              isDirectlyActive={locationArray.includes("journal")}
              isWithIndicator={hasJournalIndicator}
            />
            <MenuItem
              isExists={haveViewWorkersPermission}
              title={t("Сотрудники")}
              link={`/objects/${cachedObjectId}/workers`}
              icon={workersIcon}
              isDirectlyActive={locationArray.includes("workers")}
            />
            <MenuItem
              isExists={haveManufacturingRequisitionsListPermission}
              title={t("Заявки")}
              link={`/objects/${cachedObjectId}/requisitions`}
              icon={applications}
              isDirectlyActive={locationArray.includes("requisitions")}
            />
            <MenuItem
              isExists={haveViewStocksPermission}
              title={t("Склад")}
              link={`/objects/${cachedObjectId}/stocks`}
              icon={warehouses}
              isDirectlyActive={locationArray.includes("stocks")}
            />
            <MenuItem
              isExists={haveViewBetonPermission}
              title="Бетон"
              link={`/objects/${cachedObjectId}/beton`}
              icon={beton}
              isDirectlyActive={locationArray.includes("beton")}
            />
            <MenuItem
              isExists={haveViewBetonPermission}
              title="Склад техники и механизмов"
              link={`/objects/${cachedObjectId}/mims-stock`}
              icon={mimsIcon}
              isDirectlyActive={locationArray.includes("mims-stock")}
            />
            <MenuItem
              isExists={haveViewMaterialsPermission}
              title={t("Ресурсы")}
              link={`/objects/${cachedObjectId}/materials`}
              icon={materials}
              isDirectlyActive={locationArray.includes("materials")}
            />
            <MenuItem
              isExists={haveViewPlanFactPermission}
              title={t("План-факт")}
              link={`/objects/${cachedObjectId}/plan-fact`}
              icon={planFact}
              isDirectlyActive={locationArray.includes("plan-fact")}
            />
            <MenuItem
              title={t("Документы")}
              link={`/objects/${cachedObjectId}/documents/`}
              icon={twoListsIcon}
              isDirectlyActive={locationArray.includes("documents")}
            />
            {haveViewSettingsSectionPermission && (
              <div className={styles.bottom}>
                <MenuItem title={t("Настройки")} link={`/objects/${cachedObjectId}/settings`} icon={settingGear} />
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      {isConstructingControl && location.pathname.includes("/construction-control") && (
        <>
          <MenuItem
            title={t("Проекты")}
            link="/construction-control/projects"
            icon={estimateIcon}
            isDirectlyActive={locationArray[1] === "handler"}
            isWithIndicator={hasProjectsIndicator}
          />
          <MenuItem
            title={t("Журнал учета")}
            link={`/construction-control/journal/0/0/`}
            icon={jurnalIcon}
            isDirectlyActive={locationArray.includes("journal")}
            isWithIndicator={hasJournalIndicator}
          />
          <div className={styles.bottom}>
            <MenuItem title={t("Настройки")} link="/construction-control/settings" icon={settingGear} />
          </div>
        </>
      )}
      {location.pathname.indexOf("/constructing") !== -1 && (
        <>
          {haveViewBuildingsSectionPermission && (
            <MenuItem
              title={t("Проекты")}
              link="/constructing/projects"
              icon={estimateIcon}
              isDirectlyActive={locationArray[1] === "handler"}
              isWithIndicator={hasProjectsIndicator}
            />
          )}
          {haveViewConstructingChartPermission && (
            <MenuItem title={t("График")} link={`/constructing/manufacturing/`} icon={scheduleIcon} />
          )}
          {haveViewFinanceSectionPermission && (
            <MenuItem
              title={t("Финансы")}
              link="/constructing/finance/0/0"
              icon={finance}
              isDirectlyActive={locationArray[1] === "finance"}
            />
          )}
          {haveViewConstructingCounterpartiesPermission && (
            <MenuItem title={t("Контрагенты")} link="/constructing/counterparties" icon={contractors} />
          )}
          {haveViewConstructingDocumentsPermission && (
            <MenuItem title={t("Документы")} link="/constructing/documents/" icon={twoListsIcon} />
          )}
          {haveViewConstructingAnalyticsPermission && (
            <MenuItem title={t("Аналитика")} link="/constructing/analytics/" icon={analyticsIcon} />
          )}
          {haveViewSettingsSectionPermission && (
            <div className={styles.bottom}>
              <MenuItem title={t("Настройки")} link="/constructing/settings" icon={settingGear} />
            </div>
          )}
        </>
      )}
      {location.pathname.indexOf("/purchases") !== -1 && (
        <>
          {haveViewPurchasesChartPermission && (
            <MenuItem title={t("График")} link={`/purchases/manufacturing/`} icon={scheduleIcon} />
          )}
          {haveViewRequisitionsListPermission && (
            <MenuItem title={t("Заявки")} link="/purchases/requisitions/" icon={applications} />
          )}
          {haveViewOrdersListPermission && <MenuItem title={t("Заказы")} link="/purchases/orders/" icon={graphIcon} />}
          {haveViewPurchasesDocumentsPermission && (
            <MenuItem title={t("Документы")} link="/purchases/documents/" icon={twoListsIcon} />
          )}
          {haveViewPurchasesCounterpartiesPermission && (
            <MenuItem title={t("Контрагенты")} link="/purchases/counterparties" icon={contractors} />
          )}
          <StocksProcurementsButton />
          {haveViewSettingsSectionPermission && (
            <div className={styles.bottom}>
              <MenuItem title={t("Настройки")} link="/purchases/settings" icon={settingGear} />
            </div>
          )}
        </>
      )}
      {location.pathname.indexOf("/objects") === 0 && productionsMenu()}
      {locationArray[0] === "settings" && (
        <>
          <MenuItem title={t("Задачи")} link="/settings/tasks" icon={taskIcon} />
        </>
      )}
      {location.pathname.indexOf("/tasks") !== -1 && (
        <>
          <MenuItem
            title={t("Все задачи")}
            link={`/tasks/${TASK_LISTS_TYPES.ALL}/${objectIdAsNumber}`}
            icon={tasksAll}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.ALL)}
          />
          <MenuItem
            title={t("Мои задачи")}
            link={`/tasks/${TASK_LISTS_TYPES.MY}/${objectIdAsNumber}`}
            icon={tasksMy}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.MY)}
          />
          <MenuItem
            title={t("Поручил")}
            link={`/tasks/${TASK_LISTS_TYPES.ASSIGNED}/${objectIdAsNumber}`}
            icon={tasksAssigned}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.ASSIGNED)}
          />
          <MenuItem
            title={t("Наблюдаю")}
            link={`/tasks/${TASK_LISTS_TYPES.WATCHING}/${objectIdAsNumber}`}
            icon={tasksViewing}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.WATCHING)}
          />
          <MenuItem
            title={t("Списки задач")}
            link={`/tasks/${TASK_LISTS_TYPES.LISTS}/${objectIdAsNumber}`}
            icon={tasksLists}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.LISTS)}
          />
          {haveViewSettingsSectionPermission && (
            <div className={styles.bottom}>
              <MenuItem title={t("Настройки")} link="/tasks/settings/" icon={settingGear} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NavigationBar;
