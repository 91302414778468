import React from "react";

import TemplateSimple from "_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import ServiceAct from "./ServiceAct";

const TemplatedServiceAct = () => {
  return (
    <TemplateSimple>
      <ServiceAct isSimplified={false} />
    </TemplateSimple>
  );
};

export default TemplatedServiceAct;
