import moment from "moment";
import { useDispatch } from "react-redux";

import { sendMimStockDowntime, sendMimStockTask } from "pages/MimsStock/model/thunks";
import { IMimsStockSendToDownTimeRequest, IMimsStockSendToWorkRequest } from "pages/MimsStock/model/types";

import { useTypedSelector } from "app/store/typedUseSelector";

export const useMimsOnObjectSubmit = (stockId: number, mimId: number) => {
  const dispatch = useDispatch();
  const isLoading = useTypedSelector((state) => state.mimsStock.loadings)["toWork"];
  const invalidateKey = useTypedSelector((state) => state.mimsStock.invalidationKey);

  const onSubmit = (tab: string) => (values: IMimsStockSendToWorkRequest | IMimsStockSendToDownTimeRequest) => {
    if (tab === "task" && isToWork(values)) {
      const data: IMimsStockSendToWorkRequest = {
        ...values,
        measure: values.measure ?? "Смены",
        end_at: moment(values.end_at).format("YYYY-MM-DDThh:mm:ss"),
        start_at: moment(values.start_at).format("YYYY-MM-DDThh:mm:ss"),
      };
      dispatch(sendMimStockTask(stockId, mimId, data));
    } else {
      if (isToDowntime(values)) {
        const data: IMimsStockSendToDownTimeRequest = {
          ...values,
          end_at: moment(values.end_at).format("YYYY-MM-DDThh:mm:ss"),
          start_at: moment(values.start_at).format("YYYY-MM-DDThh:mm:ss"),
        };
        dispatch(sendMimStockDowntime(stockId, mimId, data));
      }
    }
  };

  return { onSubmit, isLoading, invalidateKey };
};

function isToWork(
  values: IMimsStockSendToWorkRequest | IMimsStockSendToDownTimeRequest
): values is IMimsStockSendToWorkRequest {
  if ("estimate_expenditure_id" in values && "section_id" in values) {
    return true;
  }
  return false;
}

function isToDowntime(
  values: IMimsStockSendToWorkRequest | IMimsStockSendToDownTimeRequest
): values is IMimsStockSendToDownTimeRequest {
  if ("customer_fault" in values || "vehicle_owner_fault" in values) {
    return true;
  }
  return false;
}
