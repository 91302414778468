import moment, { Moment } from "moment";
import React, { FC, useCallback, useMemo } from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import Select from "components/UI/atoms/Select";
import { PLANFACT_PERIOD_OPTIONS } from "pages/PlanFact/PlanFactSummary/constants";

import Calendar from "shared/ui/inputs/Calendar/Calendar";
import MonthsYearsSlider from "shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";

import { _DEPRECATED_useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";
import { useTypedParams } from "utils/hooks/useTypedParams";

import SettingGear from "images/SettingGear";

import styles from "./MimsStockMonthSlider.module.scss";

interface IProps {
  monthsCount: any;
  startAt?: any;
  endAt?: any;
  setDates?: any;
  setMonthsCount?: any;
}

const MimsStockMonthSlider: FC<IProps> = ({ startAt, endAt, monthsCount, setDates, setMonthsCount }) => {
  const { objectId } = useTypedParams();
  const { buildingData: buildingDetail } = _DEPRECATED_useBuildingDetailDataById(objectId);

  const monthSliderDate = useMemo(() => moment(startAt) || moment(), [startAt]);

  const setStartDate = useCallback(
    (startMoment: Moment | string) => {
      const startAt = moment(startMoment).format("YYYY-MM-DD");
      let _endAt = endAt;
      if (moment(_endAt).isBefore(startAt, "day")) _endAt = startAt;
      setDates(startAt, _endAt);
    },
    [endAt, setDates]
  );

  const setEndDate = useCallback(
    (endMoment: Moment | string) => {
      const endAt = moment(endMoment).format("YYYY-MM-DD");
      let _startAt = startAt;
      if (moment(_startAt).isAfter(endAt, "day")) _startAt = endAt;
      setDates(_startAt, endAt);
    },
    [startAt, setDates]
  );

  const onMonthCountChange = useCallback(
    (monthsCount: number | "all") => {
      if (
        monthsCount === "all" &&
        !!buildingDetail?.construction_date_start &&
        !!buildingDetail?.construction_date_end
      ) {
        const diff = moment(buildingDetail?.construction_date_end).diff(
          buildingDetail?.construction_date_start,
          "months"
        );
        setMonthsCount(diff);
        setDates(moment(startAt).format("YYYY-MM-DD"), moment(startAt).add(diff, "months").format("YYYY-MM-DD"));
      } else {
        setMonthsCount(monthsCount);
        setDates(moment(startAt).format("YYYY-MM-DD"), moment(startAt).add(monthsCount, "months").format("YYYY-MM-DD"));
      }
    },
    [buildingDetail?.construction_date_start, buildingDetail?.construction_date_end, startAt, setDates]
  );

  const onMonthSliderChange = useCallback((date: Moment) => {
    setDates(date.format("YYYY-MM-DD"), date.add(monthsCount, "months").format("YYYY-MM-DD"));
  }, []);

  const allMonthsCount = useMemo(() => {
    if (!buildingDetail?.construction_date_start || !buildingDetail?.construction_date_end) return 1;

    const startMoment = moment(buildingDetail?.construction_date_start);
    const endMoment = moment(buildingDetail?.construction_date_end);

    return (endMoment.year() - startMoment.year()) * 12 + (endMoment.month() - startMoment.month() + 1);
  }, [buildingDetail?.construction_date_start, buildingDetail?.construction_date_end]);

  return (
    <div className={styles.container}>
      <MonthsYearsSlider
        date={monthSliderDate}
        onChange={onMonthSliderChange}
        className={styles.slider}
        selectingMonthsCount={monthsCount === "all" ? allMonthsCount : monthsCount}
      />
      <PopoverOverlay
        openType={"click"}
        content={
          <div className={styles.periodOptions}>
            <div className={styles.periodPickers}>
              <span>Укажите период времени</span>
              <div className={styles.row}>
                <Calendar label={"Дата начала"} value={startAt} setValue={setStartDate} />
                <Calendar label={"Дата завершения"} value={startAt} setValue={setEndDate} />
              </div>
            </div>
            <div className={styles.periodSelect}>
              <span>Период:</span>
              <Select /* @ts-ignore */
                onChange={onMonthCountChange}
                value={monthsCount}
                options={PLANFACT_PERIOD_OPTIONS}
              />
            </div>
          </div>
        }
        popoverBorderColor={"default"}
        placement={"bottom"}
      >
        <SettingGear color={"#4fb2ed"} className={styles.gear} />
      </PopoverOverlay>
    </div>
  );
};

export default MimsStockMonthSlider;
