import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadShedules, shedulesSelector } from "redux/modules/common/building/shedules";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { EXPENDITURE_TYPES } from "constants/constant";
import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

export const useMimsOnObjectModalSetup = () => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const sections = useSelector(shedulesSelector)?.sections;
  const [works, setWorks] = useState<any>([]);
  const [activeSection, setActiveSection] = useState<any>();

  const subSections = sections && [].concat(...sections?.map((section: any) => section.subsections)).filter((x) => x);

  const sectionsList = useMemo(() => {
    return subSections && subSections.length > 0
      ? subSections
          .filter(
            (subsection: any) => subsection.count_expenditures.work > 0 || subsection.count_expenditures.groups > 0
          )
          .map((item: any) => ({ id: item?.id, name: item?.name, label: item?.name }))
      : [];
  }, [subSections]);

  const worksList = useMemo(() => {
    return works && works.length > 0
      ? works
          // .filter((el: any) => !isExpenditureSharedToMyContractor(el) && !isExpenditureSharedToMeButCantAddInterval(el)) TODO: Проверить, что расшаренных расценок нет в списке
          .map((item: any) => ({ id: item?.id, name: item?.name, label: item?.name, number: item?.number }))
      : /* .filter(
          (el) =>
            el.name.trim().toLowerCase().includes(searchWork.trim().toLowerCase()) ||
            String(el.number).includes(searchWork)
        ) */
        [];
  }, [works]);

  const handleChangeSection = useCallback(
    (newActiveSubSectionId: number | string) => {
      setActiveSection(+newActiveSubSectionId);
      const activeSubSection = subSections.find((section: any) => +section.id === +newActiveSubSectionId);
      const groupedIds = activeSubSection?.groups?.flatMap((group: any) => group?.expenditure_ids);
      const worksAndGroups = (
        activeSubSection?.groups?.map((group: any) => ({ ...group, isGroup: true })) || []
      ).concat(
        activeSubSection?.expenditures?.filter(
          (expenditure: any) =>
            !groupedIds?.includes(expenditure.id) && expenditure.expenditure_type === ExpenditureTypeEnum.work
        ) || []
      );
      setWorks(worksAndGroups);
    },
    [subSections]
  );

  useEffect(() => {
    dispatch(
      loadShedules(objectId, { expenditure_type: EXPENDITURE_TYPES.WORK, exclude_shared: 1, without_hidden: 1 })
    );
  }, [objectId]);

  return {
    handleChangeSection,
    sectionsList,
    worksList,
  };
};
