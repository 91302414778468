import moment from "moment";
import React, { FC, ReactNode, useState } from "react";

import { IMimsStockItem } from "pages/MimsStock/model/types";
import MimsFileModal from "pages/MimsStock/widgets/MimsFileModal/MimsFileModal";
import MimsLinkToEstimateModal from "pages/MimsStock/widgets/MimsLinkToEstimateModal/MimsLinkToEstimateModal";

import ProductInfoModal from "entities/ProductInfoModal/ProductInfoModal";
import { NavLinkIcon } from "shared/ui/controls/NavLinkIcon/NavLinkIcon";
import MatchingIcon from "shared/ui/dataDisplay/MatchingIcon/MatchingIcon";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import styles from "./MismWaitingTable.module.scss";

const variants: Record<IMimsStockItem["link_status"], "black" | "green" | "blue"> = {
  linked: "blue",
  out_of_estimate: "green",
  unlinked: "black",
};

interface IProps {
  item: IMimsStockItem;
  providersDict: any;
  stockId: number;
  colsPercents: number[];
  onClick?: (item: IMimsStockItem) => void;
  additionalColsInEnd?: { render: (item: IMimsStockItem) => ReactNode }[];
}

const MismWaitingTableRow: FC<IProps> = ({
  item,
  providersDict,
  stockId,
  colsPercents,
  onClick,
  additionalColsInEnd,
}) => {
  const rootPath = useUrlModuleWithObjectId();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isMatchingOpen, setIsMatchingOpen] = useState(false);

  const onMatchingIconClick = () => {
    if (item.link_status === "unlinked") {
      setIsMatchingOpen(true);
    } else {
      setIsInfoOpen(true);
    }
  };

  return (
    <>
      <TableReusableRow
        className={styles.row}
        onClick={() => {
          onClick?.(item);
        }}
      >
        <RowGrid percents={colsPercents}>
          <TableReusableCell>{item.id}</TableReusableCell>
          <TableReusableCell isNoBreak>{item.name}</TableReusableCell>
          <TableReusableCell isNoBreak>{providersDict[item.provider]?.name || "-"}</TableReusableCell>
          <TableReusableCell>{moment(item.delivery_date).format("DD.MM.YYYY")}</TableReusableCell>
          <TableReusableCell>
            <MatchingIcon
              onClick={onMatchingIconClick}
              variant={variants[item.link_status]}
              isWithAttentionSign={item.link_status === "unlinked"}
            />
          </TableReusableCell>
          <TableReusableCell>
            <NavLinkIcon to={`/${rootPath}/orders/${item.order_id}`} title={`Заказ ${item.order_number}`}></NavLinkIcon>
          </TableReusableCell>
          <TableReusableCell isNoBreak>{item.author}</TableReusableCell>
          <TableReusableCell isCentered>{item.count}</TableReusableCell>
          <TableReusableCell isCentered>{item.measure}</TableReusableCell>
          <TableReusableCell>
            <MimsFileModal item={item} />
          </TableReusableCell>
          {additionalColsInEnd?.map((el, i) => (
            <React.Fragment key={i}>{el.render(item)}</React.Fragment>
          ))}
        </RowGrid>
      </TableReusableRow>
      <ProductInfoModal
        isOpen={isInfoOpen}
        onClose={() => setIsInfoOpen(false)}
        canEdit
        onOpenEditing={() => {}}
        section={item.estimate_expenditure?.section}
        number={item.estimate_expenditure?.number?.[0]}
        justification={item.estimate_expenditure?.justification}
        expenditureName={item.name}
        price={item.estimate_expenditure?.price}
        count={item.estimate_expenditure?.count}
      />
      <MimsLinkToEstimateModal
        isOpen={isMatchingOpen}
        onClose={() => setIsMatchingOpen(false)}
        stockId={stockId}
        mimId={item.id}
      />
    </>
  );
};

export default MismWaitingTableRow;
