import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CommonFilesModal from "components/modals/ExpenditureFilesModal/CommonFilesModal";
import ProductFilesModal from "components/pages/Requisition/components/ProductFilesModal/ProductFilesModal";
import { getMimsStockFiles, mimsStockAttachFile } from "pages/MimsStock/model/thunks";
import { IMimsStockItem } from "pages/MimsStock/model/types";

import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";

interface IProps {
  item: IMimsStockItem;
}

const MimsFileModal: FC<IProps> = ({ item }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getMimsStockFiles(item.mim_stock, item.id));
  }, [item]);

  return (
    <>
      <FilesClipButton count={0} onClick={() => setIsOpen(true)} />
      <CommonFilesModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={() => {}}
        onUpload={(file) => {
          dispatch(mimsStockAttachFile(item.mim_stock, item.id, [file]));
        }}
        onDirectlyDelete={(id) => {}}
        attachments={[]}
      />
    </>
  );
};

export default MimsFileModal;
