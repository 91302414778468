import { useEffect, useMemo, useState } from "react";

import { mimsStockApi } from "../model/api";
import { useTypedSelector } from "app/store/typedUseSelector";

interface IProvider {
  name: string;
}

export const useMimsProviders = () => {
  const items = useTypedSelector((state) => state.mimsStock.items);

  const providerIds = useMemo(() => {
    const allIds = Object.values(items).reduce<number[]>((acc, cur) => {
      return acc.concat(cur.map((el) => el.provider));
    }, []);
    return Array.from(new Set(allIds));
  }, [items]);

  const [providersDict, setProvidersDict] = useState<Record<string, IProvider>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newIds = providerIds.filter((id) => !providersDict[id]);

    if (newIds?.length) {
      const reqs = newIds.map((id) => mimsStockApi.getProvider(id));
      setIsLoading(true);
      Promise.all(reqs)
        .then((resps) => {
          resps.forEach(({ data }, i) => {
            setProvidersDict((prev) => ({ ...prev, [newIds[i]]: data }));
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [providerIds]);

  return {
    providersDict,
    isProvidersLoading: isLoading,
  };
};
