export interface IMimsStockIdResponse {
  id: number;
  account: number;
  name: string;
}

export interface IGetMimsListParams {
  status: "waiting" | "on_object" | "in_work" | "downtime";
  delivery_date_after: string;
  delivery_date_before: string;
}

export interface IMimsStockItem {
  id: number;
  mim_stock: number;
  count: string;
  measure: string;
  name: string;
  status: string;
  provider: number;
  order_number: string;
  author: string;
  delivery_date: string;
  has_link_with_estimate: boolean;
  order_id: number;
  service_act_id: number;
  link_status: "linked" | "out_of_estimate" | "unlinked";
  estimate_expenditure?: {
    section: string;
    number: [number];
    justification: string;
    price: string;
    count: string;
    budget: number;
  };
}

export type IMimsStockSendToWorkRequest = {
  count: string;
  section_id: number; //# id секции сметы, по которой будет работа
  estimate_expenditure_id: number; // # id расценки, по которой будет
  responsible_id: number; //# id пользователя (User) ответственного за
  description: string; //# Описание работы
  start_at: string; //"2024-08-16"; //# Начало работы
  end_at: string; //"2024-08-16"; //# Окончание работы
  measure: string; //# Единица измерения
};

export const MimsStockCustomerFaults = {
  "07": "Отсутствие материалов и конструкций",
  "08": "Отсутствие фронта работ",
  "09": "Необеспеченность объездных путей",
  "10": "Отсутствие силовой энергии и освещения",
  "11": "Недостатки транспортных средств",
  "12": "Прочие простои",
} as const;

export const MimsStockVehicleOwnerFaults = {
  "01": "Неисправность машины",
  "02": "Техническое обслуживание",
  "03": "Внеплановый ремонт",
  "04": "Отсутствие горюче-смазочных материалов",
  "05": "Перебазирование и переоборудование машины",
  "06": "Отсутствие машиниста",
} as const;

export type IMimsStockSendToDownTimeRequest = {
  start_at: string;
  end_at: string; //"2024-08-24T00:00:00",
} & (
  | { customer_fault: keyof typeof MimsStockCustomerFaults }
  | { vehicle_owner_fault: keyof typeof MimsStockVehicleOwnerFaults }
);

export type IMimStockResponsible = { id: number; full_name: string };
