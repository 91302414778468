import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getMimsStockList } from "../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";

import { IGetMimsListParams } from "../model/types";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IProps {
  stockId: number;
  startAt: IGetMimsListParams["delivery_date_after"];
  endAt: IGetMimsListParams["delivery_date_before"];
  status: IGetMimsListParams["status"];
}

export const useMimsStockData = ({ stockId, startAt, endAt, status }: IProps) => {
  const dispatch = useDispatch();
  const invalidationKey = useTypedSelector((state) => state.mimsStock.invalidationKey);

  useEffect(() => {
    dispatch(getMimsStockList(stockId, { status, delivery_date_before: endAt, delivery_date_after: startAt }));
  }, [stockId, startAt, endAt, invalidationKey, status]);

  const key = generateStorageKey({
    stockId,
    status,
    delivery_date_before: endAt,
    delivery_date_after: startAt,
  } as any);

  const items = useTypedSelector((state) => state.mimsStock.items)[key];
  const isLoading = useTypedSelector((state) => state.mimsStock.loadings)[key];

  return { items, isLoading };
};
