import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import MimsOnObject from "./MimsOnObject/MimsOnObject";
import MimsWaiting from "./MimsWaiting/MimsWaiting";
import { getMimStockId } from "./model/thunks";
import MimsStockMonthSlider from "./ui/MimsStockMonthSlider/MimsStockMonthSlider";
import { useTypedSelector } from "app/store/typedUseSelector";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import TabBar from "shared/ui/controls/TabBar/TabBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { useMimsProviders } from "./hooks/useMimsProviders";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import EmptyIcon from "images/icons/navigation/mims.svg";

import styles from "./MimsStock.module.scss";

const MimsStock = () => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = useState(moment().add(1, "month").format("YYYY-MM-DD"));
  const [monthsCount, setMonthsCount] = useState(1);

  const baseRoute = useUrlModuleWithObjectId();
  const tabs = [
    { link: `/${baseRoute}/mims-stock/waiting/`, text: "Ожидается" },
    { link: `/${baseRoute}/mims-stock/on-object/`, text: "На объекте" },
  ];

  useEffect(() => {
    dispatch(getMimStockId(objectId));
  }, [objectId]);

  const key = generateStorageKey({ buildingId: objectId, type: "getStockId" });
  const isLoading = useTypedSelector((state) => state.mimsStock.loadings)[key];
  const stockId = useTypedSelector((state) => state.mimsStock.stockIds)[objectId];

  const { providersDict, isProvidersLoading } = useMimsProviders();

  const onChangeDates = (dateStart: any, dateEnd?: any) => {
    setDateStart(moment(dateStart).format("YYYY-MM-DD"));
    dateEnd && setDateEnd(moment(dateEnd).format("YYYY-MM-DD"));
  };

  const isNoStock = !isLoading && !stockId;

  return (
    <TemplateBase>
      <ListPageHeaderTemplate>
        <TabBar tabs={tabs} />
        {stockId && (
          <MimsStockMonthSlider
            monthsCount={monthsCount}
            setDates={onChangeDates}
            startAt={dateStart}
            endAt={dateEnd}
            setMonthsCount={setMonthsCount}
          />
        )}
      </ListPageHeaderTemplate>
      {isNoStock && <EmptyPlaceholder text="На данном объекте нет склада техники и механизмов" img={EmptyIcon} />}
      {isLoading && <Spinner />}
      {!isLoading && !!stockId && (
        <Switch>
          <Route path={`/${baseRoute}/mims-stock/waiting/`}>
            <MimsWaiting
              startAt={dateStart}
              endAt={dateEnd}
              stockId={stockId}
              isProvidersLoading={isProvidersLoading}
              providersDict={providersDict}
            />
          </Route>
          <Route path={`/${baseRoute}/mims-stock/on-object/`}>
            <MimsOnObject
              startAt={dateStart}
              endAt={dateEnd}
              stockId={stockId}
              isProvidersLoading={isProvidersLoading}
              providersDict={providersDict}
            />
          </Route>
          <Redirect to={`/${baseRoute}/mims-stock/waiting/`} />
        </Switch>
      )}
    </TemplateBase>
  );
};

export default MimsStock;
