import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IMimStockResponsible, IMimsStockItem } from "./types";
import { IIdAndName } from "types/idAndName";

interface MimsStockState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  stockIds: Record<string, number>;
  items: Record<string, IMimsStockItem[]>;
  responsibles: Record<string, IIdAndName[]>;
  files: Record<string, any>;
}

export const initialState: MimsStockState = {
  invalidationKey: 0,
  loadings: {},
  stockIds: {},
  items: {},
  responsibles: {},
  files: {},
};

const mimsStockSlice = createSlice({
  name: "mimsStock",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setStockId: (state, action: PayloadAction<{ stockId: number; buildingId: string }>) => {
      const { stockId, buildingId } = action.payload;
      state.stockIds[buildingId] = stockId;
    },
    setItems: (state, action: PayloadAction<{ items: IMimsStockItem[]; key: string }>) => {
      const { key, items } = action.payload;
      state.items[key] = items;
    },
    setResponsibles: (state, action: PayloadAction<{ items: IMimStockResponsible[]; key: string }>) => {
      const { key, items } = action.payload;
      state.responsibles[key] = items.map((el) => ({ ...el, name: el.full_name }));
    },
    setFiles: (state, action: PayloadAction<{ items: any[]; key: string }>) => {
      const { key, items } = action.payload;
      state.files[key] = items;
    },
  },
});

export const mimsStockActions = mimsStockSlice.actions;

export const mimsStockReducer = mimsStockSlice.reducer;
