import React from "react";
import { useDispatch } from "react-redux";

import AddRelationToProduct from "components/UI/_TODO/AddRelationToProduct";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { linkMimToEstimate } from "pages/MimsStock/model/thunks";

import { IIsOpenProps } from "shared/types/utilTypes";

interface IProps extends IIsOpenProps {
  stockId: number;
  mimId: number;
}

const MimsLinkToEstimateModal = ({ isOpen, onClose, mimId, stockId }: IProps) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();

  return (
    <AddRelationToProduct
      idAddRelation={isOpen ? ({ id: 1488 } as any) : null}
      handleClose={onClose}
      objectId={objectId}
      externalOnSelect={() => {}}
      onSubmit={(expId, estimatePath) => {
        dispatch(linkMimToEstimate(stockId, mimId, expId));
      }}
      updateProducts={() => {}}
    />
  );
};

export default MimsLinkToEstimateModal;
