import React, { ReactNode } from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";

import styles from "./MismWaitingTable.module.scss";

interface IProps {
  colsPercents: number[];
  additionalColsInEnd?: { renderLabel: () => ReactNode }[];
}

const MismWaitingTableHeader = ({ colsPercents, additionalColsInEnd }: IProps) => {
  return (
    <TableReusableHead isSticky className={styles.row}>
      <RowGrid percents={colsPercents}>
        <TableReusableHeaderCell>№</TableReusableHeaderCell>
        <TableReusableHeaderCell>Наименование</TableReusableHeaderCell>
        <TableReusableHeaderCell>Поставщик</TableReusableHeaderCell>
        <TableReusableHeaderCell>Поставка</TableReusableHeaderCell>
        <div />
        <TableReusableHeaderCell>Заказ</TableReusableHeaderCell>
        <TableReusableHeaderCell>Автор</TableReusableHeaderCell>
        <TableReusableHeaderCell isCentered>Кол-во</TableReusableHeaderCell>
        <TableReusableHeaderCell isCentered>Ед. изм.</TableReusableHeaderCell>
        <TableReusableHeaderCell></TableReusableHeaderCell>
        {additionalColsInEnd?.map((el, i) => (
          <React.Fragment key={i}>{el.renderLabel()}</React.Fragment>
        ))}
      </RowGrid>
    </TableReusableHead>
  );
};

export default MismWaitingTableHeader;
