import React from "react";
import { Field } from "react-final-form";

import Select from "components/UI/atoms/Select";
import { IMimsStockItem, MimsStockCustomerFaults, MimsStockVehicleOwnerFaults } from "pages/MimsStock/model/types";

import { FormApi } from "final-form";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import TextArea from "shared/ui/inputs/TextArea/TextArea";

import styles from "./MimsOnObjectModal.module.scss";

interface IProps {
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;
  values: Record<string, any>;
  item: IMimsStockItem;
}

const customerFaultsOptions = Object.entries(MimsStockCustomerFaults).map(([id, name]) => ({ id, name }));
const vehicleOwnerFaultsOptions = Object.entries(MimsStockVehicleOwnerFaults).map(([id, name]) => ({ id, name }));

const MimsOnObjectToDowntimeTab = ({ values, form, item }: IProps) => {
  return (
    <div className={styles.stack}>
      <Field
        name="start_at"
        render={({ input, meta }) => (
          <CalendarRange
            label="Дата"
            defaultDateEnd={values["end_at"]}
            defaultDateStart={values["start_at"]}
            setDefaultDateEnd={(d) => form.change("end_at", d)}
            setDefaultDateStart={(d) => form.change("start_at", d)}
          />
        )}
      />
      <Field
        name="customer_fault"
        render={({ input, meta }) => (
          <Select label="По вине заказчика" options={customerFaultsOptions} input={input} meta={meta} />
        )}
      />
      <Field
        name="vehicle_owner_fault"
        render={({ input, meta }) => (
          <Select label="По вине владельца машины" options={vehicleOwnerFaultsOptions} input={input} meta={meta} />
        )}
      />
      <Field
        name="description"
        render={({ input, meta }) => (
          //@ts-ignore
          <TextArea {...input} {...meta} label="Описание" placeholder="Введите описание" minRows={3} maxRows={6} />
        )}
      />
    </div>
  );
};

export default MimsOnObjectToDowntimeTab;
