import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import { IMimsStockItem } from "pages/MimsStock/model/types";

import MimsOnObjectTaskTab from "./MimsOnObjectTaskTab";
import MimsOnObjectToDowntimeTab from "./MimsOnObjectToDowntimeTab";
import { useMimsOnObjectSubmit } from "./useMimsOnObjectSubmit";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks, { ITab } from "shared/ui/controls/TabBar/TabBarNotLinks";

import styles from "./MimsOnObjectModal.module.scss";

interface IProps {
  item: IMimsStockItem;
}

const tabs: ITab[] = [
  { id: "task", text: "Задание" },
  { id: "downtime", text: "Простой" },
];

const MimsOnObjectModal = ({ item }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState(tabs[0].id);

  const { onSubmit, isLoading, invalidateKey } = useMimsOnObjectSubmit(item.mim_stock, item.id);

  useEffect(() => {
    setIsOpen(false);
  }, [invalidateKey]);

  return (
    <>
      <ButtonBase primary onClick={() => setIsOpen(true)}>
        В работу
      </ButtonBase>
      <ModalContainer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        name={item.name}
        customHeadline={
          <div className={styles.tabs}>
            <TabBarNotLinks isOnlyTabsView tabs={tabs} activeId={tab} onClick={setTab} />
          </div>
        }
      >
        <Form
          onSubmit={onSubmit(tab)}
          render={({ form, values, handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                {tab === "task" && isOpen && <MimsOnObjectTaskTab form={form as any} values={values} item={item} />}
                {tab == "downtime" && <MimsOnObjectToDowntimeTab form={form as any} values={values} item={item} />}
                <BottomControls isExists isDoubleBtns>
                  <ButtonBase onClick={() => setIsOpen(false)} secondary type="reset">
                    Отменить
                  </ButtonBase>
                  <ButtonBase primary type="submit" isLoading={isLoading}>
                    Подтвердить
                  </ButtonBase>
                </BottomControls>
              </form>
            </>
          )}
        />
      </ModalContainer>
    </>
  );
};

export default MimsOnObjectModal;
