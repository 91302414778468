import React, { FC } from "react";

import { IMimsStockItem } from "pages/MimsStock/model/types";

import MismWaitingTableHeader from "./MismWaitingTableHeader";
import MismWaitingTableRow from "./MismWaitingTableRow";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import EmptyIcon from "images/icons/navigation/mims.svg";

const defaultCols = [3, 25, 16, 10, 4, 10, 12, 5, 11, 4];

interface IProps {
  items: IMimsStockItem[];
  providersDict: any;
  stockId: number;
  colsPercents?: number[];
  onClick?: (item: IMimsStockItem) => void;
  showSpinner?: boolean;
  additionalCols?: {
    render: (item: IMimsStockItem) => JSX.Element;
    renderLabel: () => JSX.Element;
  }[];
}

const MimsStockTable: FC<IProps> = ({
  colsPercents = defaultCols,
  items,
  showSpinner,
  providersDict,
  stockId,
  onClick,
  additionalCols,
}) => {
  return (
    <>
      <MismWaitingTableHeader colsPercents={colsPercents} additionalColsInEnd={additionalCols} />
      {showSpinner && <Spinner />}
      {!showSpinner && !items?.length && <EmptyPlaceholder img={EmptyIcon} />}
      {!showSpinner &&
        items?.map((el) => (
          <MismWaitingTableRow
            key={el.id}
            item={el}
            providersDict={providersDict}
            stockId={stockId}
            onClick={onClick}
            colsPercents={colsPercents}
            additionalColsInEnd={additionalCols}
          />
        ))}
    </>
  );
};

export default MimsStockTable;
