import React, { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import MimsStockTable from "../ui/MismWaitingTable/MimsStockTable";
import MimsOnObjectModal from "./MimsOnObjectModal/MimsOnObjectModal";

import { IMimsStockItem } from "../model/types";

import { useMimsStockData } from "../hooks/useMimsStockData";

interface IProps {
  stockId: number;
  providersDict: Record<string, any>;
  isProvidersLoading: boolean;
  startAt: any;
  endAt: any;
}

const colsPercents = [3, 22, 13, 9, 4, 9, 11, 5, 10, 5, 10];

const getAdditionalCols = () => {
  return [{ render: (item: IMimsStockItem) => <MimsOnObjectModal item={item} />, renderLabel: () => <div /> }];
};

const MimsOnObject: FC<IProps> = ({ stockId, providersDict, isProvidersLoading, startAt, endAt }) => {
  const dispatch = useDispatch();
  const { isLoading, items } = useMimsStockData({ stockId, startAt, endAt, status: "on_object" });

  const showSpinner = isLoading || isProvidersLoading;

  const additionalCols = useMemo(() => {
    return getAdditionalCols();
  }, []);

  return (
    <div>
      <MimsStockTable
        showSpinner={showSpinner}
        items={items}
        providersDict={providersDict}
        stockId={stockId}
        additionalCols={additionalCols}
        colsPercents={colsPercents}
      />
    </div>
  );
};

export default MimsOnObject;
