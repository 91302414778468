import React, { HTMLAttributes } from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";

import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";

import styles from "./TextArea.module.scss";

interface IProps extends Omit<HTMLAttributes<TextareaAutosizeProps>, "onChange" | "height"> {
  label?: React.ReactNode;
  error?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  maxRows?: number;
  minRows?: number;
}

const TextArea: React.FC<IProps> = ({
  label,
  error,
  value,
  onBlur,
  onChange,
  onFocus,
  maxRows = 3,
  minRows,
  ...restProps
}) => {
  return (
    <LabeledItem label={label} error={error} isDefaultHeight={false}>
      <TextareaAutosize
        {...(restProps as any)}
        maxRows={maxRows}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        minRows={minRows}
      />
    </LabeledItem>
  );
};

export default TextArea;
