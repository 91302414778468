import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addUsing, loadUsing, usingLoadingSelector, usingSelector } from "redux/modules/common/building/stocks";

import { useFinanceMeasure } from "../../../../../features/financeMeasure/useFinanceMeasure";
import { Spinner } from "../../../../../shared/ui/atoms/Spinner/Spinner";
import Tableheader from "./../common/TableHeader/TableHeader";
import ExpenseTableRow from "./ExpenseTableRow/ExpenseTableRow";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { hasOnlyPaginationFilters } from "../../../../../utils/hasOnlyPaginationFilters";

import warehouses from "images/icons/navigation/warehouses.svg";

const Expense = ({ stockId, objectId }) => {
  const { measure } = useFinanceMeasure();
  const dispatch = useDispatch();
  const using = useSelector(usingSelector);
  const isLoading = useSelector(usingLoadingSelector);
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });

  const isWithFilters = useMemo(() => !hasOnlyPaginationFilters(filters), [filters]);

  useEffect(() => {
    stockId && loadUseings();
  }, [filters, stockId, objectId]);

  const loadUseings = () => {
    dispatch(loadUsing(stockId, filters));
  };

  const changeFilters = (name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAdd = () => {
    dispatch(addUsing(stockId, { ...filters, offset: using?.results?.length }));
  };

  const isEmpty = !isLoading && !using?.results?.length;

  return (
    <>
      {((isEmpty && isWithFilters) || !isEmpty) && (
        <Tableheader
          onFiltersChange={changeFilters}
          lastColName="Принято"
          preLastColName="В производстве"
          prePreLastColName="Выдано"
          filter_name="name"
          filter_section="section_name"
        />
      )}
      {isLoading && <Spinner />}
      {!isLoading && !!using?.results?.length
        ? using?.results?.map((el) => <ExpenseTableRow stockId={stockId} key={el.id} row={el} measure={measure} />)
        : null}
      {isEmpty && <EmptyPlaceholder img={warehouses} />}
      <ShowMoreButton
        handleAdd={handleAdd}
        isExists={!isLoading && using?.count > using?.results.length}
        allCount={using?.count}
        showedCount={using?.results.length}
      />
    </>
  );
};

export default Expense;
