import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { useDispatch } from "react-redux";

import Select from "components/UI/atoms/Select";
import { getMimStockResponsibles } from "pages/MimsStock/model/thunks";
import { IMimsStockItem } from "pages/MimsStock/model/types";

import { useMimsOnObjectModalSetup } from "./useMimsOnObjectModalSetup";
import { useTypedSelector } from "app/store/typedUseSelector";
import { FormApi } from "final-form";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import { LabelPlacement } from "shared/ui/inputs/Checkbox/Checkbox";
import InputBase from "shared/ui/inputs/InputBase";
import { Switch } from "shared/ui/inputs/Switch/Switch";
import TextArea from "shared/ui/inputs/TextArea/TextArea";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./MimsOnObjectModal.module.scss";

interface IProps {
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;
  values: Record<string, any>;
  item: IMimsStockItem;
}

const MimsOnObjectTaskTab = ({ values, form, item }: IProps) => {
  const dispatch = useDispatch();
  const { sectionsList, worksList, handleChangeSection } = useMimsOnObjectModalSetup();

  useEffect(() => {
    dispatch(getMimStockResponsibles(item.mim_stock));
  }, [item]);

  const responsibles = useTypedSelector((state) => state.mimsStock.responsibles)[item.mim_stock];
  const isRespsLoading = useTypedSelector((state) => state.mimsStock.loadings)[
    generateStorageKey({ stockId: item.mim_stock, type: "loadResponsibles" })
  ];

  return (
    <div className={styles.stack}>
      <Field
        name="count"
        render={({ input, meta }) => (
          <InputBase label="Количество" meta={meta} input={input} placeholder="Введите количество" />
        )}
      />
      <Field
        name="section_id"
        render={({ input, meta }) => (
          <Select
            label="Раздел"
            options={sectionsList}
            meta={meta}
            onChange={(v) => {
              handleChangeSection(v);
              input.onChange(v);
            }}
          />
        )}
      />
      <Field
        name="estimate_expenditure_id"
        render={({ input, meta }) => <Select label="Работа" options={worksList} input={input} meta={meta} />}
      />
      <Field
        name="description"
        render={({ input, meta }) => (
          //@ts-ignore
          <TextArea {...input} {...meta} label="Описание" placeholder="Введите описание" minRows={3} maxRows={6} />
        )}
      />
      <Field
        name="start_at"
        render={({ input, meta }) => (
          <CalendarRange
            label="Дата"
            defaultDateEnd={values["end_at"]}
            defaultDateStart={values["start_at"]}
            setDefaultDateEnd={(d) => form.change("end_at", d)}
            setDefaultDateStart={(d) => form.change("start_at", d)}
          />
        )}
      />
      <Field
        name="responsible_id"
        render={({ input, meta }) => (
          <Select label="Ответственный" options={responsibles} input={input} meta={meta} isLoading={isRespsLoading} />
        )}
      />
      <Field
        name="measure"
        render={({ input, meta }) => (
          <LabeledItem label="Время работы">
            <Switch
              onChange={(e) => input.onChange(e.target.checked ? "Смены" : "Маш. час")}
              checked={input.value !== "Маш. час"}
              label={input.value || "Смены"}
              labelPlacement={LabelPlacement.RIGHT}
            />
          </LabeledItem>
        )}
      />
    </div>
  );
};

export default MimsOnObjectTaskTab;
