import { message } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "redux";

import {
  isAcceptingServiceActSelector,
  isServiceActLoadingSelector,
  isServiceActNotFoundSelector,
  serviceActFilesSelector,
  serviceActSelector,
} from "redux/modules/common/building/documents/serviceActs/selectors";
import {
  acceptServiceActRequest,
  changeAct,
  changeServiceActNumber,
  deleteServiceActFile,
  getServiceAct,
  getServiceActFiles,
  postServiceActFiles,
} from "redux/modules/common/building/documents/serviceActs/thunks";

import Footer from "../PackingList/components/Footer/Footer";
import Header from "../PackingList/components/Header/Header";
import Products from "../PackingList/components/Products/Products";
import TemplateSimple from "_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import ListValidator from "../PackingList/domain/ListValidator";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Paper from "shared/ui/layout/Paper/Paper";

import { ACT_TYPES_ENUM } from "./types/enums";
import { NOT_FOUND_PLACEHOLDER } from "constants/placeholders";
import { IFile } from "types/interfaces/Files";
import { IServiceAct } from "types/interfaces/ServiceAct";

import { useQueryParams } from "utils/hooks/useQueryParams";

import notFoundIcon from "images/icons/not-found-icon-black.svg";

import styles from "./ServiceAct.module.scss";

interface IProps {
  isSimplified?: boolean;
  externalActId?: string;
  onSubmit?: () => void;
}

const ServiceAct: React.FC<IProps> = ({ isSimplified = false, externalActId, onSubmit }) => {
  const dispatch = useDispatch();
  const { actId: actIdFromPath } = useParams<{ actId: string }>();
  const actId = externalActId ?? actIdFromPath;
  const from = useQueryParams("from");

  const serviceAct = useSelector(serviceActSelector);
  const isLoading = useSelector(isServiceActLoadingSelector);
  const isAccepting = useSelector(isAcceptingServiceActSelector);
  const isNotFound = useSelector(isServiceActNotFoundSelector);
  const files = useSelector(serviceActFilesSelector);

  const [products, setProducts] = useState<IServiceAct["items"] | null>(null);
  const [comment, setComment] = useState("");

  const commentChangeHandler = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  }, []);

  useEffect(() => {
    dispatch(getServiceAct(+actId));
    dispatch(getServiceActFiles(actId));
  }, [actId]);

  useEffect(() => {
    if (!serviceAct) return;
    setComment(serviceAct?.comment || "");
  }, [serviceAct?.comment]);

  useEffect(() => {
    if (!serviceAct) return;

    setProducts(serviceAct.items || []);
  }, [serviceAct?.items]);

  const productsWithoutRemoved = useMemo(() => products?.filter((product) => !product.is_removed) || [], [products]);

  const onChangeActNumber = useCallback(
    (number: string) => {
      compose(dispatch, changeServiceActNumber)(actId, number);
    },
    [actId]
  );

  const changeExecutionDate = useCallback(
    (date: moment.Moment) => {
      compose(dispatch, changeAct)(actId, { date: moment(date).format("YYYY-MM-DD") });
    },
    [actId]
  );

  const handleAddFiles = useCallback((files: IFile[]) => compose(dispatch, postServiceActFiles)(actId, files), [actId]);

  const handleDeleteFile = useCallback(
    (removedFileId: number) => compose(dispatch, deleteServiceActFile)(actId, removedFileId),
    [actId]
  );

  const acceptServiceAct = useCallback(
    (isSplit = false) => {
      if (!serviceAct || !products) return;
      dispatch(
        acceptServiceActRequest(
          actId,
          {
            comment,
            items: products,
            refuse_product: !isSplit,
          },
          onSubmit
        )
      );
    },
    [products, serviceAct, comment, actId, onSubmit]
  );

  const onAccept = useCallback(() => {
    const onValidatorError = (errors: string[]) => errors?.forEach((error) => message.error(error));
    const serviceActValidator = new ListValidator(onValidatorError);

    if (!serviceActValidator.validate(serviceAct?.number, products)) return;
    acceptServiceAct();
  }, [serviceAct?.number, acceptServiceAct, products, productsWithoutRemoved]);

  const canEditServiceAct = serviceAct?.status === "waiting_completion";

  if ((!serviceAct || isLoading) && !isNotFound) {
    return <Spinner />;
  }

  if (isNotFound) {
    return <EmptyPlaceholder img={notFoundIcon} text={NOT_FOUND_PLACEHOLDER} />;
  }

  return (
    <div className={styles.container}>
      {!isSimplified && (
        <BackNavigationBar title="Документы" backLink={from || undefined} rightSideText={serviceAct?.building?.name} />
      )}
      <Paper>
        <div className={styles.content}>
          <Header
            data={serviceAct as IServiceAct}
            canEdit={canEditServiceAct}
            type={ACT_TYPES_ENUM.SERVICE_ACTS}
            onChangeNumber={onChangeActNumber}
            addFiles={handleAddFiles}
            deleteFile={handleDeleteFile}
            changeExecutionDate={changeExecutionDate}
            isSimplified={isSimplified}
            propsFiles={files}
          />
          {productsWithoutRemoved.length !== 0 && (
            <Products products={productsWithoutRemoved} type={ACT_TYPES_ENUM.SERVICE_ACTS} />
          )}
          <Footer
            canEditPackingList={canEditServiceAct}
            onAccept={onAccept}
            commentChangeHandler={commentChangeHandler}
            comment={comment}
            isAccepting={isAccepting}
          />
        </div>
      </Paper>
    </div>
  );
};

export default React.memo(ServiceAct);
