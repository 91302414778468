import { message } from "antd";
import { Dispatch } from "redux";

import { mimsStockApi } from "./api";
import { mimsStockActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IGetMimsListParams, IMimsStockSendToDownTimeRequest, IMimsStockSendToWorkRequest } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getMimStockId = (buildingId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = generateStorageKey({ buildingId, type: "getStockId" });
  if (!getState()?.mimsStock.stockIds[buildingId]) {
    dispatch(mimsStockActions.setIsLoading({ status: true, key }));
  }
  mimsStockApi
    .getMimsStock(buildingId)
    .then(({ data }) => {
      if (data.results[0]?.id!) {
        dispatch(mimsStockActions.setStockId({ buildingId, stockId: data.results[0]?.id! }));
      }
    })
    .finally(() => {
      dispatch(mimsStockActions.setIsLoading({ status: false, key }));
    });
};

export const getMimsStockList =
  (stockId: number, params?: Partial<IGetMimsListParams>) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({
      stockId,
      ...params,
    });
    if (!getState()?.mimsStock.items[key]) {
      dispatch(mimsStockActions.setIsLoading({ status: true, key }));
    }
    mimsStockApi
      .getList(stockId, params)
      .then(({ data }) => {
        dispatch(mimsStockActions.setItems({ items: data.results, key }));
      })
      .finally(() => {
        dispatch(mimsStockActions.setIsLoading({ status: false, key }));
      });
  };

export const linkMimToEstimate = (stockId: number, mimId: number, expId: number) => (dispatch: Dispatch) => {
  mimsStockApi
    .linkWithEstimate(stockId, mimId, expId)
    .then(() => {
      dispatch(mimsStockActions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const getMimStockResponsibles = (stockId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const loadingKey = generateStorageKey({
    stockId,
    type: "loadResponsibles",
  });
  if (!getState()?.mimsStock.responsibles[stockId]) {
    dispatch(mimsStockActions.setIsLoading({ status: true, key: loadingKey }));
  }
  mimsStockApi
    .getResponsibles(stockId)
    .then(({ data }) => {
      dispatch(mimsStockActions.setResponsibles({ key: String(stockId), items: data }));
    })
    .finally(() => {
      dispatch(mimsStockActions.setIsLoading({ status: false, key: loadingKey }));
    });
};

export const sendMimStockTask =
  (stockId: number, mimId: number, data: IMimsStockSendToWorkRequest) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(mimsStockActions.setIsLoading({ status: true, key: "toWork" }));
    mimsStockApi
      .sendToWork(stockId, mimId, data)
      .then(() => {
        dispatch(mimsStockActions.invalidateKey());
        message.success("Успешно");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(mimsStockActions.setIsLoading({ status: false, key: "toWork" }));
      });
  };

export const sendMimStockDowntime =
  (stockId: number, mimId: number, data: IMimsStockSendToDownTimeRequest) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(mimsStockActions.setIsLoading({ status: true, key: "toWork" }));
    mimsStockApi
      .sendToDownTime(stockId, mimId, data)
      .then(() => {
        dispatch(mimsStockActions.invalidateKey());
        message.success("Успешно");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(mimsStockActions.setIsLoading({ status: false, key: "toWork" }));
      });
  };

export const mimsStockAttachFile =
  (stockId: number, mimId: number, files: File[]) => (dispatch: Dispatch, getState: () => RootState) => {
    const reqs = files.map((el) => mimsStockApi.postFiles(stockId, mimId, el));
    const loadingKey = generateStorageKey({ id: mimId, stockId, type: "files" });
    dispatch(mimsStockActions.setIsLoading({ status: true, key: loadingKey }));
    Promise.all(reqs)
      .then(() => {
        dispatch(mimsStockActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(mimsStockActions.setIsLoading({ status: false, key: loadingKey }));
      });
  };

export const getMimsStockFiles =
  (stockId: number, mimId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const loadingKey = generateStorageKey({ id: mimId, stockId, type: "files" });
    dispatch(mimsStockActions.setIsLoading({ status: true, key: loadingKey }));
    mimsStockApi
      .getFiles(stockId, mimId)
      .then(({ data }) => {
        dispatch(mimsStockActions.setFiles({ key: String(mimId), items: data.results }));
      })
      .finally(() => {
        dispatch(mimsStockActions.setIsLoading({ status: false, key: loadingKey }));
      });
  };

export const deleteMimsStockFile = (stockId: number, mimId: number, fileId: number) => (dispatch: Dispatch) => {
  const loadingKey = generateStorageKey({ id: mimId, stockId, type: "files" });
  dispatch(mimsStockActions.setIsLoading({ status: true, key: loadingKey }));
  mimsStockApi
    .deleteFiles(stockId, mimId, fileId)
    .then(() => {
      dispatch(mimsStockActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(mimsStockActions.setIsLoading({ status: false, key: loadingKey }));
    });
};
