import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ServiceAct from "components/pages/ServiceAct/ServiceAct";

import { mimsStockActions } from "../model/slice";
import MimsStockTable from "../ui/MismWaitingTable/MimsStockTable";

import { useMimsStockData } from "../hooks/useMimsStockData";
import { useQueryParams } from "utils/hooks/useQueryParams";

import styles from "./MimsWaiting.module.scss";

interface IProps {
  stockId: number;
  providersDict: Record<string, any>;
  isProvidersLoading: boolean;
  startAt: any;
  endAt: any;
}

const MimsWaiting: FC<IProps> = ({ stockId, providersDict, isProvidersLoading, startAt, endAt }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, items } = useMimsStockData({ stockId, startAt, endAt, status: "waiting" });

  const showSpinner = isLoading || isProvidersLoading;

  const actId = useQueryParams("service_act_id");

  return (
    <>
      <MimsStockTable
        showSpinner={showSpinner}
        items={items}
        providersDict={providersDict}
        onClick={({ service_act_id }) => {
          const params = new URLSearchParams();
          params.set("service_act_id", String(service_act_id));
          history.push(`?${params.toString()}`);
        }}
        stockId={stockId}
      />
      {actId && (
        <div className={styles.act}>
          <ServiceAct externalActId={actId} onSubmit={() => dispatch(mimsStockActions.invalidateKey())} />
        </div>
      )}
    </>
  );
};

export default MimsWaiting;
