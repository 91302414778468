import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import Analytics from "../../../../components/pages/Analytics/Analytics";
import Building from "../../../../components/pages/Building/Building";
import Calendar from "../../../../components/pages/Calendar";
import ConstructingCounterparties from "../../../../components/pages/Constructing/Counterparties";
import Manufacturing from "../../../../components/pages/Manufacturing/Manufacturing";
import TemplatedOrder from "../../../../components/pages/Order/TemplatedOrder";
import PaymentsList from "../../../../components/pages/PaymentsList";
import TemplatedRequisition from "../../../../components/pages/Requisition/TemplatedRequisition";
import EditProject from "../../../../components/pages/Settings/EditProject/EditProject";
import SuppliesList from "../../../../components/pages/SuppliesList";
import { ProfilePage } from "../../../../features/templates/Header/components/Profile/components/ProfilePage/ProfilePage";
import { useLoadUIIndicators } from "../../../../hooks/uiIndicators/useLoadUIIndicators";
import HandlerPage from "../../../../pages/Handler/ui/HandlerPage";
import ConstructingObjects from "components/pages/Constructing/Objects/Objects";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";
import TemplatedServiceAct from "components/pages/ServiceAct/TemplatedServiceAct";
import ConstructingFinance from "pages/Finance/Finance";
import Projects from "pages/Projects/Projects";
import Project from "pages/Settings/Project/Project";

import DocumentsRoutes from "../DocumentsRoutes/DocumentsRoutes";
import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";

import {
  VIEW_ACCEPTED_BUILDINGS_TAB,
  VIEW_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTACT,
  VIEW_ADD_CONTRACTOR,
  VIEW_ADD_CONTRACTORS_CONTRACTS,
  VIEW_ADD_CONTRACTORS_CONTRACTS_FILES,
  VIEW_ADD_CONTRACT_FILES,
  VIEW_ADD_CUSTOMERS_CONTRACTS,
  VIEW_ADD_CUSTOMERS_CONTRACTS_FILES,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_ADD_SUPPLIERS_CONTRACTS,
  VIEW_ADD_SUPPLIERS_CONTRACTS_FILES,
  VIEW_ALL_BUILDINGS_TAB,
  VIEW_ANALYTICS,
  VIEW_BILL,
  VIEW_BILLS_LIST,
  VIEW_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID,
  VIEW_BUILDING,
  VIEW_BUILDINGS_SECTION,
  VIEW_BUILDING_ADD_EXPENDITURE_FILES,
  VIEW_BUILDING_ADD_FACT_INTERVALS,
  VIEW_BUILDING_ADD_PLAN_INTERVALS,
  VIEW_BUILDING_DELETE_EXPENDITURE_FILES,
  VIEW_BUILDING_PLAN_TAB,
  VIEW_BUILDING_PROGRESS_TAB,
  VIEW_BUILDING_TRANSFER_FACT_WORKS,
  VIEW_BUILDING_TRANSFER_PLAN_WORKS,
  VIEW_CONSOLIDATE_HANDLER_STATE,
  VIEW_CONSTRUCTING_CHART,
  VIEW_CONSTRUCTING_DRAWINGS,
  VIEW_CONSTRUCTING_DRAWINGS_CREATE,
  VIEW_CONSTRUCTING_DRAWINGS_DELETE,
  VIEW_CONSTRUCTING_DRAWINGS_EDIT,
  VIEW_CONSTRUCTING_DRAWINGS_SETS_CREATE,
  VIEW_CONSTRUCTING_DRAWINGS_SETS_DELETE,
  VIEW_CONSTRUCTING_DRAWINGS_SETS_EDIT,
  VIEW_CONSTRUCTING_SECTION,
  VIEW_CONTACTS_LIST,
  VIEW_CONTRACTORS_LIST,
  VIEW_CONTRACTS_LIST,
  VIEW_COUNTERPARTIES_SECTION,
  VIEW_CREATE_BUILDING,
  VIEW_CUSTOMERS_LIST,
  VIEW_DELETE_BILL_FILES,
  VIEW_DELETE_CONTACTS,
  VIEW_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACTORS,
  VIEW_DELETE_CONTRACTORS_CONTRACTS,
  VIEW_DELETE_CONTRACTORS_CONTRACTS_FILES,
  VIEW_DELETE_CONTRACT_FILES,
  VIEW_DELETE_CUSTOMERS,
  VIEW_DELETE_CUSTOMERS_CONTRACTS,
  VIEW_DELETE_CUSTOMERS_CONTRACTS_FILES,
  VIEW_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DELETE_SUPPLIERS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES,
  VIEW_DOCUMENTS_SECTION,
  VIEW_DRAFT_HANDLER_STATE,
  VIEW_EDIT_BUILDINGS,
  VIEW_EDIT_CONTACT,
  VIEW_EDIT_CONTRACTOR_MANAGER,
  VIEW_EDIT_CUSTOMER_MANAGER,
  VIEW_EDIT_SUPPLIER_MANAGER,
  VIEW_EXPORT_LIST,
  VIEW_FINANCE_SECTION,
  VIEW_HANDLER,
  VIEW_INVITES_LIST,
  VIEW_INVITES_LIST_ACTIONS,
  VIEW_INVITE_CUSTOMER,
  VIEW_INVITE_SUPPLIER,
  VIEW_LOCALE_HANDLER_STATE,
  VIEW_OBJECT_HANDLER_STATE,
  VIEW_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PACKING_LIST_PAGE,
  VIEW_PRODUCTION_HANDLER_STATE,
  VIEW_SENT_BUILDINGS_TAB,
  VIEW_SUPPLIERS_LIST,
} from "../../../../constants/permissions/constructingPermissions";
import { VIEW_FILE_STORAGE, VIEW_SETTINGS_SECTION } from "../../../../constants/permissions/generalPermissions";
import { ROUTES } from "../../../../constants/routes";
import { CONSTRUCTING_DEFAULT_PAGES_ENUM } from "types/enums/ModulesDefaultPageEnum";

import usePermission from "../../../../hooks/usePermission";
import { useLastObjectId } from "../../../../utils/hooks/uselastObjectId";
import { usePermissionsObject } from "features/permissions/hooks/usePermissionsObject";
import { useLocationArray } from "utils/hooks/useLocationArray";

const ConstructingRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const locationArray = useLocationArray();
  const location = useLocation();

  useLastObjectId();
  useLoadUIIndicators();

  const haveViewConstructingChartPermission = usePermission(VIEW_CONSTRUCTING_CHART);
  const haveViewBuildingsSectionPermission = usePermission(VIEW_BUILDINGS_SECTION);
  const haveViewHandlerPermission = usePermission(VIEW_HANDLER);
  const haveViewFinancePermission = usePermission(VIEW_FINANCE_SECTION);
  const haveViewCounterpartiesPermission = usePermission(VIEW_COUNTERPARTIES_SECTION);
  const haveViewDocumentsPermission = usePermission(VIEW_DOCUMENTS_SECTION);
  const haveViewBuildingPermission = usePermission(VIEW_BUILDING);
  const haveViewFileStoragePermission = usePermission(VIEW_FILE_STORAGE);
  const haveViewSettingsSectionPermission = usePermission(VIEW_SETTINGS_SECTION);
  const haveViewCreateBuildingPermission = usePermission(VIEW_CREATE_BUILDING);
  const haveViewEditBuildingsPermission = usePermission(VIEW_EDIT_BUILDINGS);
  const haveViewAnalyticsPermission = usePermission(VIEW_ANALYTICS);

  useEffect(() => {
    if (
      (locationArray[1] === CONSTRUCTING_DEFAULT_PAGES_ENUM.PROJECTS ||
        locationArray[1] === CONSTRUCTING_DEFAULT_PAGES_ENUM.SCHEDULE) &&
      !isNaN(locationArray[2])
    ) {
      localStorage.setItem("lastObjectId", locationArray[2]);
    }
  }, [location]);

  const ableUrl = useMemo(() => {
    if (haveViewBuildingsSectionPermission) return `${match.url}/projects`;
    if (haveViewConstructingChartPermission) return `${match.url}/manufacturing/`;
    if (haveViewFinancePermission) return `${match.url}/finance/0`;
    if (haveViewCounterpartiesPermission) return `${match.url}/counterparties`;
    if (haveViewDocumentsPermission) return `${match.url}/documents`;
    if (haveViewAnalyticsPermission) return `${match.url}/analytics`;
  }, [
    haveViewBuildingsSectionPermission,
    haveViewConstructingChartPermission,
    haveViewCounterpartiesPermission,
    haveViewDocumentsPermission,
    haveViewFinancePermission,
    haveViewAnalyticsPermission,
    match.url,
  ]);

  const documentsPermissions = usePermissionsObject({
    viewBillsList: VIEW_BILLS_LIST,
    viewBill: VIEW_BILL,
    viewPackingLists: VIEW_PACKING_LISTS,
    viewPackingListPage: VIEW_PACKING_LIST_PAGE,
    viewPackingListsVatCalculation: VIEW_PACKING_LISTS_VAT_CALCULATION,
    viewContractsList: VIEW_CONTRACTS_LIST,
    viewDeleteContract: VIEW_DELETE_CONTRACT,
    viewAddContractFiles: VIEW_ADD_CONTRACT_FILES,
    viewDeleteContractFiles: VIEW_DELETE_CONTRACT_FILES,
    viewExportList: VIEW_EXPORT_LIST,
    viewDeleteExports: VIEW_DELETE_EXPORTS,
  });

  const billPermissions = usePermissionsObject({
    viewBillInEdit: VIEW_BILL_IN_EDIT,
    addFiles: VIEW_ADD_BILL_FILES,
    deleteFiles: VIEW_DELETE_BILL_FILES,
    viewAddBillPaymentsFile: VIEW_ADD_BILL_PAYMENTS_FILE,
    viewBillPaymentsMakePaid: VIEW_BILL_PAYMENTS_MAKE_PAID,
  });

  const packingListPermissions = usePermissionsObject({
    viewPackingListAccept: VIEW_PACKING_LIST_ACCEPT,
    addFiles: VIEW_ADD_PACKING_LIST_FILES,
    deleteFiles: VIEW_DELETE_PACKING_LIST_FILES,
  });

  const requisitionPermissions = useMemo(
    () => ({
      viewProcessRequisition: true,
      viewProcessOrders: true,
      viewProcessPayments: true,
      viewAddOutOfEstimateProducts: true,
      viewAddAdditionalProducts: true,
      viewAddProductsBeforeApprove: true,
      viewAssignRequisitionExecutor: true,
      viewRequisitionTakeToWork: true,
      viewRequisitionReassign: true,
      viewAddRequisitionApprovers: true,
      viewAddRequisitionViewers: true,
      viewDeleteRequisitionApprovers: true,
      viewDeleteRequisitionViewers: true,
      viewRequisitionUnApprove: true,
      viewRequisitionLogs: true,
      viewRequisitionInProcessingMode: true,
      viewDeleteRequisitionProductsFiles: true,
      viewAddRequisitionProductsFiles: true,
      viewRequisitionProductsComments: true,
      viewAddRequisitionProductsComments: true,
      viewCreateRequisitionProductsProvider: true,
      viewOrderInvoiceDifference: true,
      viewAddOrderRequestsFiles: true,
      viewDeleteOrderRequestsFiles: true,
    }),
    []
  );

  const orderPermissions = useMemo(
    () => ({
      viewOrderInvoiceDifference: true,
      autoTransitionToPayment: true,
      addFiles: true,
      deleteFiles: true,
      viewAddOrderRequestsFiles: true,
      viewDeleteOrderRequestsFiles: true,
    }),
    []
  );

  const counterpartiesPermissions = usePermissionsObject({
    viewContactsList: VIEW_CONTACTS_LIST,
    viewAddContact: VIEW_ADD_CONTACT,
    viewDeleteContacts: VIEW_DELETE_CONTACTS,
    viewEditContact: VIEW_EDIT_CONTACT,
    viewContractorsList: VIEW_CONTRACTORS_LIST,
    viewAddContractor: VIEW_ADD_CONTRACTOR,
    viewDeleteContractors: VIEW_DELETE_CONTRACTORS,
    viewEditContractorManager: VIEW_EDIT_CONTRACTOR_MANAGER,
    viewAddContractorsContracts: VIEW_ADD_CONTRACTORS_CONTRACTS,
    viewDeleteContractorsContract: VIEW_DELETE_CONTRACTORS_CONTRACTS,
    viewAddContractorsContractFiles: VIEW_ADD_CONTRACTORS_CONTRACTS_FILES,
    viewDeleteContractorsContractFiles: VIEW_DELETE_CONTRACTORS_CONTRACTS_FILES,
    viewSuppliersList: VIEW_SUPPLIERS_LIST,
    viewInviteSupplier: VIEW_INVITE_SUPPLIER,
    viewDeleteSuppliers: VIEW_DELETE_SUPPLIERS,
    viewEditSupplierManager: VIEW_EDIT_SUPPLIER_MANAGER,
    viewAddSuppliersContracts: VIEW_ADD_SUPPLIERS_CONTRACTS,
    viewDeleteSuppliersContract: VIEW_DELETE_SUPPLIERS_CONTRACTS,
    viewAddSuppliersContractFiles: VIEW_ADD_SUPPLIERS_CONTRACTS_FILES,
    viewDeleteSuppliersContractFiles: VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES,
    viewCustomersList: VIEW_CUSTOMERS_LIST,
    viewInviteCustomer: VIEW_INVITE_CUSTOMER,
    viewDeleteCustomers: VIEW_DELETE_CUSTOMERS,
    viewEditCustomerManager: VIEW_EDIT_CUSTOMER_MANAGER,
    viewAddCustomersContracts: VIEW_ADD_CUSTOMERS_CONTRACTS,
    viewDeleteCustomersContracts: VIEW_DELETE_CUSTOMERS_CONTRACTS,
    viewAddCustomersContractsFiles: VIEW_ADD_CUSTOMERS_CONTRACTS_FILES,
    viewDeleteCustomersContractsFiles: VIEW_DELETE_CUSTOMERS_CONTRACTS_FILES,
    viewInvitesList: VIEW_INVITES_LIST,
    viewInvitesListActions: VIEW_INVITES_LIST_ACTIONS,
  });

  const buildingsPermissions = usePermissionsObject({
    viewAllBuildingsTab: VIEW_ALL_BUILDINGS_TAB,
    viewSentBuildingsTab: VIEW_SENT_BUILDINGS_TAB,
    viewAcceptedBuildingsTab: VIEW_ACCEPTED_BUILDINGS_TAB,
    viewCreateBuilding: haveViewCreateBuildingPermission,
    viewEditBuildings: haveViewEditBuildingsPermission,
    viewHandler: haveViewHandlerPermission,
    viewBuilding: haveViewBuildingPermission,
  });

  const handlerPermissions = usePermissionsObject({
    viewDraftHandlerState: VIEW_DRAFT_HANDLER_STATE,
    viewLocaleHandlerState: VIEW_LOCALE_HANDLER_STATE,
    viewObjectHandlerState: VIEW_OBJECT_HANDLER_STATE,
    viewConsolidateHandlerState: VIEW_CONSOLIDATE_HANDLER_STATE,
    viewProductionHandlerState: VIEW_PRODUCTION_HANDLER_STATE,
    canAddFiles: true,
    canDeleteFiles: true,
  });

  const objectEstimatePermissions = usePermissionsObject({
    canViewPlan: VIEW_BUILDING_PLAN_TAB,
    canViewProgress: VIEW_BUILDING_PROGRESS_TAB,
    canAddPlan: VIEW_BUILDING_ADD_PLAN_INTERVALS,
    canAddProgress: VIEW_BUILDING_ADD_FACT_INTERVALS,
    canSharePlan: VIEW_BUILDING_TRANSFER_PLAN_WORKS,
    canShareProgress: VIEW_BUILDING_TRANSFER_FACT_WORKS,
    canAddFiles: VIEW_BUILDING_ADD_EXPENDITURE_FILES,
    canDeleteFiles: VIEW_BUILDING_DELETE_EXPENDITURE_FILES,

    canViewDrawings: VIEW_CONSTRUCTING_DRAWINGS,
    canAddDrawingSets: VIEW_CONSTRUCTING_DRAWINGS_SETS_CREATE,
    canEditDrawingSets: VIEW_CONSTRUCTING_DRAWINGS_SETS_EDIT,
    canDeleteDrawingSets: VIEW_CONSTRUCTING_DRAWINGS_SETS_DELETE,
    canAddDrawings: VIEW_CONSTRUCTING_DRAWINGS_CREATE,
    canEditDrawings: VIEW_CONSTRUCTING_DRAWINGS_EDIT,
    canDeleteDrawings: VIEW_CONSTRUCTING_DRAWINGS_DELETE,
  });

  return (
    <Switch>
      <Route exact path={match.path} render={() => <Redirect replace to={ableUrl} />} />
      {haveViewConstructingChartPermission && (
        <Route
          exact
          path={[`${match.path}/manufacturing/`, `${match.path}/manufacturing/:objectId`]}
          component={Manufacturing}
        />
      )}
      {haveViewHandlerPermission && (
        <Route
          path={`/constructing/handler/:projectId/:buildingId`}
          render={(props) => <HandlerPage {...props} permissions={handlerPermissions} />}
        />
      )}

      {haveViewFinancePermission && <Route path={ROUTES.CONSTRUCTING_FINANCE} component={ConstructingFinance} />}
      {haveViewCounterpartiesPermission && (
        <Route
          path={ROUTES.CONSTRUCTING_COUNTERPARTIES}
          render={(props) => <ConstructingCounterparties {...props} permissions={counterpartiesPermissions} />}
        />
      )}
      {haveViewDocumentsPermission && (
        <Route
          path={`${match.path}/documents`}
          render={(props) => (
            <DocumentsRoutes
              {...props}
              documentsPermissions={documentsPermissions}
              billPermissions={billPermissions}
              packingListPermissions={packingListPermissions}
            />
          )}
        />
      )}
      <Route exact path={`${match.path}/profile`} component={ProfilePage} />
      <Route exact path={`${match.path}/events/calendar`} component={Calendar} />
      <Route exact path={`${match.path}/events/payments`} component={PaymentsList} />
      <Route exact path={`${match.path}/events/supplies`} component={SuppliesList} />
      <Route exact path={`${match.path}/documents/service-acts/:actId`} component={TemplatedServiceAct} />

      <Route
        exact
        path={`${match.path}/requisitions/:activeTab/:requisitionId`}
        render={(props) => <TemplatedRequisition {...props} permissions={requisitionPermissions} />}
      />
      <Route
        exact
        path={`${match.path}/orders/:orderId`}
        render={(props) => <TemplatedOrder {...props} permissions={orderPermissions} />}
      />
      {haveViewFileStoragePermission && (
        <Route exact path={`${match.path}/files/:objectId/`} component={FilesStorage} />
      )}
      {haveViewAnalyticsPermission && <Route exact path={`${match.path}/analytics`} component={Analytics} />}
      {haveViewSettingsSectionPermission && <Route path={`${match.path}/settings`} component={SettingsRoutes} />}
      {/* PROJECTS */}

      {haveViewBuildingsSectionPermission && (
        <Route
          exact
          path={"/constructing/projects/objects-list/:projectId"}
          render={(props) => <ConstructingObjects {...props} permissions={buildingsPermissions} />}
        />
      )}
      {haveViewCreateBuildingPermission && (
        <Route path={`/constructing/projects/create-object/:projectId/:id`} component={EditProject} />
      )}
      {haveViewEditBuildingsPermission && (
        <Route path={`/constructing/projects/edit-object/:projectId/:id`} component={EditProject} />
      )}
      <Route path={`/constructing/projects/create-project/:projectId`} component={Project} />
      <Route path={`/constructing/projects/edit-project/:projectId`} component={Project} />
      {haveViewBuildingsSectionPermission && haveViewBuildingPermission && (
        <Route
          path={`/constructing/projects/:projectId/object/:objectId`}
          render={(props) => <Building {...props} permissions={objectEstimatePermissions} />}
        />
      )}
      <Route exact path={"/constructing/projects/"} render={(props) => <Projects />} />
      <ForbiddenPage />
    </Switch>
  );
};

export default React.memo(ConstructingRoutes);
